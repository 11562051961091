<script>
import api from "@/api";
import Loader from "vue-element-loading";
// import GoogleLogin from "vue-google-login";

export default {
  components: {
    Loader,
  },
  data() {
    let redirectUrl = process.env.VUE_APP_URL;
    if (!redirectUrl) {
      redirectUrl = "https://tizi.possumk.xyz";
    }
    redirectUrl = `${redirectUrl}/login`;
    return {
      returnFBUrl: `https://www.facebook.com/v13.0/dialog/oauth?client_id=189904688621203&redirect_uri=${redirectUrl}&response_type=token&scope=public_profile,email&state=fb`,
      showFB: false,
      loading: false,
      email: "",
      password: "",
      googleParams: {
        client_id:
          "54400868668-aimcss3g6soop0qll7tc8k513mc365e5.apps.googleusercontent.com",
        renderParams: {
          width: 250,
          height: 50,
          longtitle: true,
        },
      },
    };
  },
  methods: {
    facebookLogin() {
      this.loading = true;
      window.location = this.returnFBUrl;
    },
    push(next) {
      let lang = this.$route.params.lang;
      if (!lang) {
        lang = "en";
      }

      this.$router.push({
        name: next,
        params: {
          lang: lang,
        },
      });
    },
    onGoogleSuccess(googleUser) {
      const profile = googleUser.getBasicProfile();
      api.log("Getting Google user profile data.");
      const data = {
        email: profile.getEmail(),
        first_name: profile.getGivenName(),
        last_name: profile.getFamilyName(),
        googleId: profile.getId(),
        imageUrl: profile.getImageUrl(),
      };
      api.log("Successfully got Google user profile data.");
      this.loading = true;
      this.$store
        .dispatch("Auth/socialLogin", data)
        .then(this.postLogin)
        .catch((e) => {
          this.loading = false;
          console.error(e);
          api.log("Unable to perform social login for Google.");
          api.log(e, "error");
          alert("Could not login.");
        });
    },
    onGoogleFailure(details) {
      alert("There was a problem logging in with Google..");
      console.error(details);
      api.log("Proble with google login.");
    },
    passwordLogin() {
      this.login({ email: this.email, password: this.password });
    },
    login(data) {
      this.loading = true;
      this.$store
        .dispatch("Auth/passwordLogin", data)
        .then(this.postLogin)
        .catch((e) => {
          console.error(e);
          this.loading = false;
          alert("Could not login.");
        });
    },
    postLogin() {
      this.loading = false;

      const fullPath = localStorage.getItem("fullPath");
      if (fullPath) {
        let parsedPath = null;
        try {
          parsedPath = JSON.parse(fullPath);
        } catch (err) {
          console.error(err);
        }
        window.localStorage.removeItem("fullPath");
        let path = "RehearsalSpaces";
        const newQuery = {};
        if (parsedPath == null) {
          this.$router.push(fullPath);
          return;
        }
        path = parsedPath.p;
        if (path) {
          Object.assign(newQuery, parsedPath);
          if (newQuery.r) {
            delete newQuery.r;
          }
          if (newQuery.p) {
            delete newQuery.p;
          }
        }
        const routeObj = { path: path };
        if (newQuery) {
          routeObj.query = newQuery;
        }
        this.$router.push(routeObj);
        return;
      }

      let next = this.$store.state.Auth.next;
      if (!next) {
        next = localStorage.getItem("next");
      }
      if (next) {
        // remove this item from next
        localStorage.removeItem("next");
        this.push(next);
        return;
      }
      this.push("RehearsalSpaces");
    },
    getFBDataFromToken(token) {
      this.loading = true;
      api.Util.axios
        .post("/getFBDataFromToken/", { token: token })
        .then(({ data }) => {
          if (!data.job) {
            this.loading = false;
            return;
          }
          api.log(`Getting data using token ${token}.`);
          api.Util.getTaskResult(data.job).then((response) => {
            const responseData = response.data;
            const imageUrl = responseData?.picture?.data?.url;
            const data = {
              email: responseData.email,
              first_name: responseData.first_name,
              last_name: responseData.last_name,
              name: responseData.name,
              fbId: responseData.id,
              imageUrl: imageUrl,
            };
            api.log("Successfully got facebook user profile data.");
            this.loading = true;
            this.$store
              .dispatch("Auth/socialLogin", data)
              .then(this.postLogin)
              .catch((e) => {
                this.loading = false;
                console.error(e);
                api.log("Unable to perform social login for Google.");
                api.log(e, "error");
                alert("Could not login.");
              });
          });
        });
    },
  },
  computed: {
    canLogin() {
      if (!this.password) {
        return false;
      }
      if (!this.email) {
        return false;
      }
      return true;
    },
  },
  created() {
    const hash = this.$route.hash;
    if (this.$route.query?.showFB === "1") {
      this.showFB = true;
    }
    if (hash && hash.includes("access_token=")) {
      const other = hash.replace(/^#/, "");
      const parts = other.split("&");
      const partMap = {};
      for (const part of parts) {
        const innerPart = part.split("=");
        partMap[innerPart[0]] = innerPart[1];
      }
      if (partMap.access_token !== null && partMap.state === "fb") {
        api.log("Getting fb data from token.");
        this.getFBDataFromToken(partMap.access_token);
      } else {
        api.log(
          `Unable to get FB data from token. hash: ${hash} access_token:${partMap.access_token} state:${partMap.state}`
        );
      }
    }
  },
};
</script>

<template lang="html">
  <div id="login">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col py-3 mt-3 bg-white bd-gray" id="col-form">
          <Loader :active="loading" />
          <div class="row justify-content-center">
            <form class="col-md-4 col-10 mt-2" @submit.prevent="passwordLogin">
              <div class="form-group mt-2">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  v-model="email"
                />
                <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                >
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  v-model="password"
                />
              </div>

              <div class="form-group my-1">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="!canLogin"
                >
                  Login
                </button>
              </div>

              <div class="form-group my-1 row">
                <div class="col">
                  <router-link :to="{ name: 'Register' }"
                    >Register an account</router-link
                  >
                </div>

                <div class="col">
                  <router-link :to="{ name: 'ForgotPassword' }"
                    >Forgot password?</router-link
                  >
                </div>
              </div>
            </form>
          </div>

          <!-- <div class="row justify-content-center"> -->
          <!--   <div class="col-6"> -->
          <!--     <hr /> -->
          <!--   </div> -->
          <!-- </div> -->

          <!-- <div class="row justify-content-center mb-5"> -->
          <!--   <div class="col-10 col-xl-6"> -->
          <!--     <div class="row"> -->
          <!--       <div class="col text-center py-2" id="google"> -->
          <!--         <GoogleLogin -->
          <!--           :params="googleParams" -->
          <!--           :onSuccess="onGoogleSuccess" -->
          <!--           :onFailure="onGoogleFailure" -->
          <!--           @click="loading = true" -->
          <!--         > -->
          <!--           <i class="fab fa-google"></i> -->
          <!--           Login with Google -->
          <!--         </GoogleLogin> -->
          <!--       </div> -->
          <!---->
          <!--       <div id="fb" class="col text-center py-2" v-if="showFB"> -->
          <!--         <button -->
          <!--           id="fbButton" -->
          <!--           class="btn btn-primary" -->
          <!--           @click="facebookLogin" -->
          <!--         > -->
          <!--           <i class="fab fa-facebook"></i> -->
          <!--           Login with Facebook -->
          <!--         </button> -->
          <!--       </div> -->
          <!--     </div> -->
          <!--   </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
#google button {
  background: red;
  border: red;
  color: white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#col-form {
  border: 1px solid #ccc;
  border-radius: 3px;
}
</style>
