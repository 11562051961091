<script>
import api from "@/api";
import moment from "moment";
import TimePicker from "@/components/timepicki.vue";
import BookingMixin from "@/mixins/Booking";
import SpaceLocationMixin from "@/mixins/SpaceLocation";

import Loader from "vue-element-loading";
import DatePicker from "vue2-datepicker";

export default {
  props: ["space", "timeObj"],
  components: {
    Loader,
    TimePicker,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      titleManual: false,
      initialStartTime: null,
      modalData: {
        group: "",
        product: "",
        title: "",
        date: null,
        time: null,
        duration_in_hours: 3,
        discount: "0",
        internal_booked_text: "Internal Use",
        internalUse: false,
        shouldPay: true,
      },
    };
  },
  methods: {
    makeBooking() {
      const postData = Object.assign({}, this.modalData);
      const timezoneOffset = new Date().getTimezoneOffset();
      postData.timezoneOffset = 0 - timezoneOffset; // offset must be reversed
      const startTime = `${postData.date}T${postData.time}`;
      let sign = postData.timezoneOffset > 0 ? "+" : "-";
      let offsetHoursMins = BookingMixin.minutesToHoursAndMinutes(
        postData.timezoneOffset
      );
      let stringData = `${startTime}${sign}${offsetHoursMins}`;
      postData.start_time = moment(stringData).toISOString();
      if (!postData.title) {
        postData.title = postData.group;
      }
      postData.slug = this.space.slug;
      const url = "/schedules/make_manager_booking/";
      this.loading = true;
      api.Util.axios
        .post(url, postData)
        .then(() => {
          // reload events
          this.$emit("refetchEvents");
          this.$emit("hideModal");
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 400) {
            const data = err?.response?.data;
            if (data.non_field_errors) {
              alert(data.non_field_errors[0]);
            } else {
              alert("There has been an error making this booking.");
              console.error(err?.response);
            }
          } else {
            alert("Error adding manual booking");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hideModal() {
      this.$emit("hideModal");
    },
    toggleInternalUse(event) {
      if (event.target.checked) {
        this.modalData.group = "";
        this.titleManual = true;
        this.modalData.shouldPay = false;
        this.allowManualtitle();
        if (this.modalData.title === "") {
          this.modalData.title = this.modalData.internal_booked_text;
        }
      } else {
        // not for internal use
        this.modalData.title = "";
        this.titleManual = false;
        this.modalData.shouldPay = true;
      }
    },
    toggleManual(event) {
      this.allowManualtitle({ manual: event.target.checked });
    },
    allowManualtitle(argObj) {
      var metCondition = this.titleManual;
      if (argObj && argObj.manual !== undefined) {
        metCondition = argObj.manual;
      }

      if (metCondition) {
        this.modalData.group = "";
      } else {
        this.modalData.title = "";
        this.modalData.internalUse = false;
      }
    },
    setStartTime(data) {
      this.modalData.time = data.time;
    },
  },
  computed: {
    slProductObj() {
      if (!this.modalData.product) {
        return null;
      }
      const prod = this.products.find((item) => {
        item.id == this.modalData.product;
      });
      if (prod) {
        return prod;
      }
      return null;
    },
    products() {
      if (this.space) {
        return this.space.products;
      }
      return [];
    },
    defaultProduct() {
      return SpaceLocationMixin.getDefaultProduct(this.space);
    },
    groups() {
      return this.$store.state.Manager.groups;
    },
    steps() {
      if (!this.modalData.group && !this.modalData.title) {
        return { canBook: false, failedAt: "group_title" };
      }
      if (!this.modalData.date) {
        return { canBook: false, failedAt: "date" };
      }
      if (!this.modalData.time) {
        return { canBook: false, failedAt: "time" };
      }
      return { canBook: true, failedAt: null };
    },
    canAddBookingManually() {
      return this.steps.canBook;
    },
  },
  created() {
    if (this.defaultProduct) {
      this.modalData.product = this.defaultProduct.id;
    }

    const params = BookingMixin.getDateTimeParamsFromCalendarTimeObj(
      this.timeObj
    );
    if (params) {
      this.modalData.date = params.date;
      if (params.time) {
        this.modalData.time = params.time;
        this.initialStartTime = this.modalData.time.split(":")[0];
      }
      if (params.duration_in_hours) {
        this.modalData.duration_in_hours = params.duration_in_hours;
      }
    }
  },
};
</script>

<template>
  <div
    class="modal fade show"
    id="makeBookingModal"
    tabindex="-1"
    aria-labelledby="EventModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <Loader :active="loading" />
        <div class="modal-header">
          <h5 class="modal-title" id="eventModalLabel">Add</h5>
          <button
            class="btn btn-primary close"
            type="button"
            v-on:click.prevent="hideModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <form id="modal-form">
            <div class="form-group row">
              <div class="col-12 col-sm-5">
                <label for="title">Group</label>
              </div>
              <div class="col">
                <select v-model="modalData.group" :disabled="titleManual">
                  <option value="">----</option>
                  <option
                    v-for="group in groups"
                    :value="group.name"
                    :key="group.name"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12 col-sm-5">
                <input
                  class="mx-1"
                  type="checkbox"
                  v-model="titleManual"
                  @click="toggleManual($event)"
                />
                <label for="title">Use Manual title :</label>
              </div>
              <div class="col">
                <input
                  :disabled="!titleManual"
                  placeholder="title"
                  v-model="modalData.title"
                  type="text"
                  class="form-control"
                  id="title"
                  required
                />
              </div>
            </div>

            <div
              class="form-group row pt-2"
              :class="modalData.internalUse ? 'alert-primary' : ''"
            >
              <div class="col-12 col-sm-5">
                <label for="title"
                  >For Internal Use: <br />
                  <small>Will not be charged</small>
                </label>
              </div>
              <div class="col">
                <input
                  type="checkbox"
                  v-model="modalData.internalUse"
                  @click="toggleInternalUse($event)"
                />
              </div>
            </div>

            <div
              class="form-group row pt-2"
              :class="!modalData.shouldPay ? 'alert-danger' : ''"
            >
              <div class="col-12 col-sm-5">
                <label for="title">Client Should Be Charged? <br /> </label>
              </div>
              <div class="col">
                <input type="checkbox" v-model="modalData.shouldPay" />
              </div>
            </div>

            <div class="form-group row pt-2">
              <div class="col-12 col-sm-5">
                <label for="start">Product</label>
              </div>
              <div class="col" v-if="space">
                <select class="form-control" v-model="modalData.product">
                  <template v-for="product of products">
                    <option :key="product.id" :value="product.id">
                      {{ product.name }} @ {{ product.hourly_rate }} per hour
                    </option>
                  </template>
                </select>
                <small class="text-secondary" v-if="slProductObj"
                  >{{ openDays }} from {{ spaceStartHourReadable }} for
                  {{ slProductHours }} hours.
                </small>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12 col-sm-5">
                <label for="start">Start Date</label>
              </div>
              <DatePicker v-model="modalData.date" valueType="format" />
            </div>

            <div class="form-group row">
              <div class="col-12 col-sm-5">
                <label for="start">Start Time</label>
              </div>
              <div class="col" v-if="space">
                <time-picker
                  :show_30s="space.allow_half_hour_bookings"
                  id="timepicker1"
                  :start="initialStartTime"
                  @change="setStartTime"
                ></time-picker>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12 col-sm-5">
                <label for="duration_in_hours">Duration in Hours</label>
              </div>
              <div class="col" v-if="space">
                <input
                  v-model="modalData.duration_in_hours"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>

            <div
              class="form-group row"
              v-if="!modalData.internalUse && modalData.shouldPay"
            >
              <div class="col-12 col-sm-5">
                <label for="discount">Discount</label>
              </div>
              <div class="col">
                <input
                  v-model="modalData.discount"
                  type="text"
                  class="form-control"
                  id="discount"
                  placeholder="Discount"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            v-on:click.prevent="makeBooking"
            class="btn btn-success"
            :disabled="!canAddBookingManually"
          >
            Add Booking Manually
          </button>

          <button
            type="submit"
            v-on:click.prevent="hideModal"
            class="btn btn-danger"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#makeBookingModal {
  display: block;
}
</style>
