import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";

import * as Sentry from "@sentry/vue";

require("./assets/css/fullcalendar.min.css");
require("bootstrap/dist/css/bootstrap.min.css");
require("@fortawesome/fontawesome-free/css/all.css");
require("vue-toast-notification/dist/theme-sugar.css");
import "vue-select/dist/vue-select.css";

Vue.use(VueToast, { position: "top-right" });
Object.defineProperty(Vue.prototype, "$toast", { value: VueToast });
Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://133c64403c35667de9c681139c27432e@o4507806105993216.ingest.us.sentry.io/4507806129192960",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/tizi\.possumk\.xyz\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function createVue() {
  window.vueInstance = new Vue({
    router,
    store,
    render: (h) => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  }).$mount("#app");
}

function init() {
  const token = localStorage.getItem("tiziAuthToken");
  if (token) {
    const promises = [];
    promises.push(store.dispatch("Auth/updateToken", token));
    promises.push(store.dispatch("Auth/tryLogin", token));
    Promise.all(promises)
      .catch((err) => {
        alert("Error loading app.");
        console.error(err);
      })
      .then(() => {
        createVue();
      });
  } else {
    createVue();
  }
}

init();
