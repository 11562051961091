<script>
import VueSimpleSuggest from "vue-simple-suggest";
import Loader from "vue-element-loading";
import TimePicker from "@/components/timepicki.vue";
import moment from "moment";
import api from "@/api";
import SpaceLocationMixin from "@/mixins/SpaceLocation";
import GeneralMixin from "@/mixins/General";
import BookingMixin from "@/mixins/Booking";

export default {
  name: "Booking",
  props: ["setAlertMessage"],
  components: { Loader, TimePicker, VueSimpleSuggest },
  data() {
    return {
      loading: false,
      initialized: false,
      makingRequest: false,

      overrideStartTimeDigits: null,

      bookingData: {
        date: null,
        time: null,
        duration_in_hours: 3,
        title: "",
        notes: "",
        product: null,
        phone: "",
      },

      error_message: null,
      title_error: "",

      agreed_to_terms: false,
    };
  },
  methods: {
    loadFromQueryParams() {
      var data = this.$route.query;
      if (!data) {
        return;
      }
      if (data.time) {
        this.overrideStartTimeDigits = data.time;
      }

      for (const key of [
        "date",
        "time",
        "duration_in_hours",
        "product",
        "phone",
        "notes",
        "title",
      ]) {
        const value = data[key];
        if (value) {
          this.bookingData[key] = value;
        }
      }
      this.agreed_to_terms = data.agreed_to_terms;
      if (this.loggedIn) {
        this.checkIfOtherBooking();
      }
    },
    validateTimeAgainstProduct() {
      if (!this.bookingData.time || !this.slProductObj) {
        return;
      }
      const startMins = BookingMixin.hoursAndMinsToMins(this.bookingData.time);
      if (this.slProductObj.start_time_mins > startMins) {
        this.bookingData.time = null;
        alert("Chosen start time is earlier than the chosen product allows.");
      }
    },
    validateDateAgainstProduct() {
      if (!this.bookingData.date) {
        return false;
      }
      var m = moment(this.bookingData.date, "YYYY-MM-DD");
      const chosenDayOfWeek = m.format("ddd");
      let errorMsg = "";
      let resetDate = false;
      let canSetToToday = true;
      if (this.slProductObj && this.slProductObj.days_of_week_csv) {
        const daysOfWeek = this.slProductObj.days_of_week_csv.split(",");
        const isNotFound = daysOfWeek.indexOf(chosenDayOfWeek) == -1;
        if (isNotFound) {
          // not found
          canSetToToday = false;
          resetDate = true;
          errorMsg = `For this product, the space is only open on ${this.openDays}.`;
          this.bookingData.date = null;
        }
      }

      if (resetDate && canSetToToday) {
        errorMsg = `${errorMsg} Resetting date to today.`;
        this.bookingData.date = SpaceLocationMixin.getTodaysDate();
      }

      if (errorMsg) {
        alert(errorMsg);
        return false;
      } else {
        return true;
      }
    },
    setStartTimeFromPicker(data) {
      this.bookingData.time = data.time;
    },
    toLogin() {
      const query = {
        r: "Booking",
        p: this.$route.path,
        ...this.bookingData,
      };
      const stringQuery = JSON.stringify(query);
      localStorage.setItem("fullPath", stringQuery);
      this.$router.replace({
        name: "Login",
      });
    },
    canShowForm() {
      // we don't show the form if use is logged in and has not groups
      if (this.loggedIn && this.groups.length === 0) {
        return false;
      }
      return true;
    },
    chooseGroup(group) {
      this.bookingData.title = group;
    },
    start_suggestion(t) {
      this.overrideStartTimeDigits = t;
    },

    makeBooking: function () {
      if (!this.loggedIn) {
        this.toLogin();
        return;
      }

      var total = null;
      try {
        total = parseInt(this.calculateTotal);
      } catch (ex) {
        console.error(ex);
      }
      if (total === null) {
        alert("Error submitting values. Something on the form is still wrong.");
      }

      if (this.bookingData.title === null) {
        alert("Please choose a group!");
        return;
      }
      const timezoneOffset = new Date().getTimezoneOffset();
      let data = Object.assign({}, this.bookingData);
      data.slug = this.slug;
      data.timezoneOffset = 0 - timezoneOffset; // offset must be reversed
      let sign = data.timezoneOffset > 0 ? "+" : "-";
      let offsetHoursMins = BookingMixin.minutesToHoursAndMinutes(
        data.timezoneOffset
      );
      const startTime = `${this.bookingData.date}T${this.bookingData.time}`;
      let stringData = `${startTime}${sign}${offsetHoursMins}`;
      data.start_time = moment(stringData).toISOString();
      var url = "/schedules/";
      this.$store.dispatch("General/updateLoading", true);
      this.makingRequest = true;
      api.Util.axios
        .post(url, data)
        .then((response) => {
          this.$store.dispatch("General/updateLoading", false);
          this.makingRequest = false;
          if (response.status === 201) {
            this.$router.push({ name: "Notifications" });
          } else {
            alert("Unable to add rehearsal. " + response.status);
          }
        })
        .catch((err) => {
          this.$store.dispatch("General/updateLoading", false);
          this.makingRequest = false;
          if (err?.response?.status === 401) {
            this.toLogin();
          } else if (err?.response?.status === 403) {
            console.log(err?.response);
            alert(err.response.data.message);
          } else if (err?.response?.status === 400) {
            const data = err?.response?.data;
            if (data.non_field_errors) {
              alert(data.non_field_errors[0]);
            } else {
              alert(
                "There has been an error making this booking. Please contact the studio manually."
              );
              console.error(err?.response);
            }
          }
        });
    },
    cancel_submit: function () {
      this.$router.push({
        name: "Space-Sections",
        params: { slug: this.slug },
      });
    },
    getGroupsForUser() {
      return new Promise((resolve) => {
        if (!this.$store.state.Auth.loggedIn) {
          return resolve();
        }

        if (this.groups.length === 1) {
          this.bookingData.title = this.groups[0].name;
        }
        resolve();
      });
    },
    getHour(time, amPm) {
      if (!time) {
        return;
      }
      var parts = time.split(":");
      var p1 = parseInt(parts[0]);
      if (amPm === "PM" && p1 < 12) {
        p1 += 12;
      } else if (p1 === 12 && amPm === "AM") {
        p1 = 0;
      }

      var p1String = p1.toString();
      if (p1String.length === 1) {
        p1String = `0${p1String}`;
      }
      return p1String + ":" + parts[1] + ":00";
    },
    getPic(space) {
      return SpaceLocationMixin.getPic(space);
    },
    checkIfOtherBooking() {
      if (!this.isGoodTimeAndDuration) {
        return;
      }
      this.loading = true;

      const startTime = `${this.bookingData.date}T${this.bookingData.time}`;
      const params = {
        start: startTime,
        duration_in_hours: this.bookingData.duration_in_hours,
        slug: this.space.slug,
        timezoneOffset: 0 - new Date().getTimezoneOffset(),
      };
      return api.Util.axios
        .get("/schedules/bookings_pending_at_time/", {
          params: params,
        })
        .then((response) => {
          const activeCount = response.data.active;
          const inactiveCount = response.data.inactive;
          if (activeCount > 0) {
            alert("This booking would overlap with an existing booking.");
          } else if (inactiveCount > 0) {
            alert(
              "Someone else is also awaiting approval for a slot around this time."
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    canShowStep(index) {
      const finalIndex = index - 1;
      const steps = [
        true, //product
        this.bookingData.product, //date
        this.bookingData.date, //time
        true, //duration
        this.bookingData.time, //title
        true, //phone
        this.isValidPhone,
        this.canShowTotalTOS,
      ];
      for (let i = 0; i <= finalIndex; i++) {
        if (!steps[i]) {
          // console.log(index, finalIndex, "Failed at step " + (i+1));
          return false;
        }
      }
      return true;
    },
  },
  computed: {
    slProductHours() {
      if (!this.slProductObj) {
        return null;
      }
      return this.slProductObj.duration_mins / 60;
    },
    spaceStartHourReadable() {
      if (!this.slProductObj) {
        return null;
      }
      return BookingMixin.readableHoursAndMins(
        this.slProductObj.start_time_mins
      );
    },
    openDays() {
      if (this.slProductObj && this.slProductObj.days_of_week_csv) {
        const daysOfWeek = this.slProductObj.days_of_week_csv.split(",");
        return daysOfWeek.join(", ");
      }
      return [];
    },
    isOpenForChosenProduct() {
      if (!this.slProductObj || !this.slProductObj.days_of_week_csv) {
        return false;
      }
      if (!this.bookingData.date) {
        return false;
      }
      var m = moment(this.bookingData.date, "YYYY-MM-DD");
      const chosenDayOfWeek = m.format("ddd");
      return this.openDays.indexOf(chosenDayOfWeek) > -1;
    },
    maxHoursForChosenProduct() {
      if (this.slProductObj) {
        return Math.floor(this.slProductObj.duration_mins / 60);
      }
      return -1;
    },
    defaultProduct() {
      return SpaceLocationMixin.getDefaultProduct(this.space);
    },
    products() {
      if (this.space) {
        return this.space.products;
      }
      return [];
    },
    slProductObj() {
      const obj = this.products.find(
        (item) => item.id == this.bookingData.product
      );
      if (obj) {
        return obj;
      }
      return null;
    },
    canShowTotalTOS() {
      return this.isGoodTimeAndDuration && this.isValidPhone;
    },
    spaceStartHour() {
      if (this.slProductObj) {
        const startTimeMins = this.slProductObj.start_time_mins;
        const startHour = Math.floor(startTimeMins / 60);
        return startHour;
      }
      return 0;
    },
    isValidPhone() {
      const phone = this.bookingData.phone;
      if (!phone) {
        return false;
      }
      if (phone.length >= 9) {
        return true;
      }
      return false;
    },
    shouldDisable() {
      if (this.hasTos && !this.agreed_to_terms) {
        console.log("TOS");
        return "TOS";
      }

      if (!this.bookingData.phone) {
        console.log("phone");
        return "phone";
      }

      if (this.makingRequest) {
        console.log("makingRequest");
        return "makingRequest";
      }
      return 0;
    },
    getSuccessClass() {
      var classes = "btn btn-success ";
      if (this.shouldDisable !== 0) {
        classes += " disabled";
      }
      return classes;
    },
    groups() {
      return this.$store.state.Auth.groups || [];
    },
    space() {
      return this.$store.getters["General/getSpaceBySlug"](this.slug);
    },
    hasTos() {
      if (this.tos) {
        return true;
      }
      return false;
    },
    tos() {
      if (this.space) {
        return this.space.tos;
      }
      return "";
    },
    slug() {
      return this.$route.params.slug;
    },
    loggedIn() {
      return this.$store.state.Auth.loggedIn;
    },
    calculateTotalCurrency() {
      return "KES " + this.calculateTotal;
    },
    calculateTotal() {
      if (this.slProductObj) {
        var t =
          this.bookingData.duration_in_hours * this.slProductObj.hourly_rate;
        return t;
      }
      return "---";
    },
    isGoodTimeAndDuration() {
      if (!this.bookingData.date || !this.bookingData.time) {
        return false;
      }
      if (
        !this.bookingData.duration_in_hours ||
        Number.parseInt(this.bookingData.duration_in_hours) < 1
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    "bookingData.date"(v) {
      if (!this.initialized) {
        return;
      }
      let errorMsg = null;
      var m = moment(v, "YYYY-MM-DD");
      var diff = m.fromNow();
      if (diff.indexOf("ago") > -1 && diff.indexOf("hours") === -1) {
        var vReadable = moment(v, "YYYY-MM-DD").format("dddd,Do MMM Y");
        errorMsg = `Rehearsal date '${vReadable}' is in the past.`;
      }
      if (errorMsg) {
        this.bookingData.date = null;
        alert(errorMsg);
        return;
      }
      if (this.bookingData.date) this.validateDateAgainstProduct();
      GeneralMixin.replaceHref(this, this.bookingData);
      if (this.initialized) {
        this.checkIfOtherBooking();
      }
    },
    "bookingData.product"() {
      if (this.bookingData.product) {
        if (this.bookingData.date) {
          this.validateDateAgainstProduct();
        }
        if (this.bookingData.time) {
          this.validateTimeAgainstProduct();
        }
      }
    },
    "bookingData.time"() {
      if (this.bookingData.time && this.bookingData.product) {
        this.validateTimeAgainstProduct();
      }
      if (this.initialized) {
        this.checkIfOtherBooking();
      }
    },
    "bookingData.duration_in_hours"() {
      if (this.initialized) {
        this.checkIfOtherBooking();
      }
    },
    bookingData: {
      handler() {
        if (!this.initialized) {
          return;
        }
        GeneralMixin.replaceHref(this, this.bookingData);
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.agreed_to_terms && confirm("Finish making booking?")) {
        this.makeBooking();
      }
    }, 500);
  },
  async created() {
    this.initialized = false;
    this.loading = true;

    if (this.slug === null || !this.slug) {
      this.$router.back();
    }

    this.loadFromQueryParams();

    if (!this.loggedIn) {
      this.toLogin();
      return;
    }

    if (!this.bookingData.product && this.products.length) {
      if (this.defaultProduct) {
        this.bookingData.product = this.defaultProduct.id;
      }
    }

    if (!this.bookingData.phone) {
      this.bookingData.phone = this.$store.state?.Auth.authData?.phone;
    }
    this.validateDateAgainstProduct();

    await this.getGroupsForUser();
    this.loading = false;
    this.initialized = true;
  },
};
</script>

<template>
  <div id="Booking">
    <Loader :active="loading" />
    <div class="container pt-2">
      <div class="row justify-content-center">
        <div id="booking_form" class="col-10 col-md-8">
          <div class="title row">
            <div class="col-12 col-sm-2 text-center align-items-center">
              <template v-if="space != null">
                <template v-if="getPic(space) != ''">
                  <div class="col-12 col-sm-2">
                    <img
                      class="img-thumbnail"
                      v-bind:src="SpaceLocationMixin.getPic(space)"
                      alt=""
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="row justify-content-center align-items-center">
                    <div class="col-sm-6 col-12">
                      <i
                        class="fas fa-2x fa-basketball-ball"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <template v-if="space != null">
              <h3 class="col col-md-7 mr-auto name text-center fw-bold">
                <router-link
                  :to="{ name: 'Space-Sections', params: { slug: space.slug } }"
                  >{{ space.name }}</router-link
                >
              </h3>
            </template>
          </div>

          <form>
            <template v-if="canShowForm">
              <div class="form-group row my-2" v-if="canShowStep(1)">
                <label for="" class="col-sm-2 text-md-right">Product</label>
                <div class="col-sm-10 col-md-7">
                  <select class="form-control" v-model="bookingData.product">
                    <template v-for="product of products">
                      <option :key="product.id" :value="product.id">
                        {{ product.name }} @ {{ product.hourly_rate }} per hour
                      </option>
                    </template>
                  </select>
                  <small class="text-secondary" v-if="slProductObj"
                    >{{ openDays }} from {{ spaceStartHourReadable }} for
                    {{ slProductHours }} hours.
                  </small>
                </div>
              </div>

              <div class="form-group row my-2" v-if="canShowStep(2)">
                <label class="col-sm-2 text-md-right" for="startTime"
                  >Date:</label
                >
                <div class="col-sm-8 col-md-7">
                  <div class="form-group input-group">
                    <div class="input-group-prepend p-1">
                      <span class="input-group-text">
                        <i class="fa fa-calendar-day"></i>
                      </span>
                    </div>

                    <input
                      class="form-control"
                      type="date"
                      v-model="bookingData.date"
                      id="date"
                      aria-describedby="datehelp"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row my-2" v-if="canShowStep(3)">
                <label class="col-sm-2 text-md-right" for="time">Time:</label>
                <div class="col-sm-10 col-md-7">
                  <div class="form-group input-group">
                    <div class="input-group-prepend p-1">
                      <span class="input-group-text">
                        <i class="fa fa-clock"></i>
                      </span>
                    </div>
                    <time-picker
                      :start="spaceStartHour"
                      :show_30s="space.allow_half_hour_bookings"
                      :override="overrideStartTimeDigits"
                      @change="setStartTimeFromPicker"
                      :maxHours="maxHoursForChosenProduct"
                      id="timepicker1"
                    ></time-picker>
                  </div>
                  <div class="row">
                    <div class="col-12 text-danger">
                      {{ title_error }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row my-2" v-if="canShowStep(4)">
                <label for="" class="col-sm-2 text-md-right"
                  >Duration In Hours</label
                >
                <div class="col-sm-10 col-md-7">
                  <input
                    v-model="bookingData.duration_in_hours"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="form-group row my-2" v-if="canShowStep(5)">
                <label class="col-sm-2 text-md-right" for="title"
                  >Group Name</label
                >
                <div class="col-sm-10 col-md-7">
                  <div class="form-group input-group">
                    <div class="input-group-prepend p-1">
                      <span class="input-group-text">
                        <i class="fa fa-guitar"></i>
                      </span>
                    </div>
                    <vue-simple-suggest
                      class="col"
                      v-model="bookingData.title"
                      :styles="{ defaultInput: 'form-control' }"
                      display-attribute="name"
                      :list="groups"
                      :filter-by-query="true"
                    >
                      <!-- Filter by input text to only show the matching results -->
                    </vue-simple-suggest>
                  </div>

                  <div class="row">
                    <div class="col-12 text-danger">
                      {{ title_error }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row my-2" v-if="canShowStep(6)">
                <label class="col-sm-2 text-md-right" for="phone">Phone </label>
                <div class="col-sm-10 col-md-7">
                  <div class="form-group input-group">
                    <div class="input-group-prepend p-1">
                      <span class="input-group-text">
                        <i class="fa fa-phone"></i>
                      </span>
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      v-model="bookingData.phone"
                      placeholder="Used in case of cancellations."
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row my-2" v-if="canShowStep(7)">
                <div class="col-sm-10 col-md-7 offset-md-2">
                  <div class="form-group input-group">
                    <div class="input-group-prepend p-1">
                      <span class="input-group-text">
                        <i class="fa fa-sticky-note"></i>
                      </span>
                    </div>
                    <textarea
                      v-model="bookingData.notes"
                      placeholder="Any notes to go with your booking."
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>

              <template v-if="canShowStep(8)">
                <div class="form-group row separator border"></div>

                <div class="form-group row" v-if="tos">
                  <label class="col-sm-2 text-md-right">Terms Of Service</label>
                  <div class="col-sm-8">
                    <div class="form-group input-group">
                      <div class="input-group-prepend p-1">
                        <span class="input-group-text">
                          <i class="fa fa-hat-cowboy-side"></i>
                        </span>
                      </div>
                      <textarea
                        class="disabled form-control"
                        v-model="tos"
                        cols="30"
                        rows="5"
                        disabled
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div
                  class="row justify-content-center text-center"
                  id="error_message"
                  v-if="error_message"
                >
                  <div class="col-sm-6 col-10 bg-danger text-white">
                    {{ error_message }}
                  </div>
                </div>

                <transition name="total_transition animate fadeInUp" appear>
                  <div class="form-group row my-2">
                    <label class="col-sm-2 text-md-right">Total</label>
                    <div class="col-sm-8">
                      <input
                        disabled
                        type="text"
                        class="form-control"
                        id="total"
                        v-bind:value="calculateTotalCurrency"
                      />
                    </div>
                  </div>
                </transition>

                <div class="form-group row justify-content-center">
                  <div class="col-md-10 offset-md-2" v-if="hasTos">
                    <div class="row" id="buttons">
                      <div
                        class="col-12"
                        :class="agreed_to_terms ? '' : 'text-danger'"
                      >
                        <input
                          type="checkbox"
                          class="regular-checkbox"
                          v-model="agreed_to_terms"
                        />
                        <span v-if="!agreed_to_terms">
                          <i class="fas fa-exclamation-circle"></i>
                        </span>
                        I have read and agree to the
                        <router-link
                          :to="{ name: 'TOS', params: { slug: slug } }"
                        >
                          terms of service(TOS)</router-link
                        >
                        of this rehearsal space.
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <a
                      href="#"
                      id="successBtn"
                      class="offset-sm-2 col-sm-8 my-2"
                      :class="getSuccessClass"
                      @click.prevent="makeBooking"
                      >I want to make this booking</a
                    >
                  </div>
                  <!-- <div class="col-12"> -->
                  <!--   <a href="#" class="btn offset-sm-2 col-sm-8" @click.prevent="cancel_submit">Cancel</a> -->
                  <!-- </div> -->
                </div>
              </template>
            </template>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-group .input-group {
  margin-bottom: 3px;
}
#error_message div {
  padding: 5px;
  margin-bottom: 10px;
}

#Booking {
  background: url(/static/dist/assets/img/bg5.jpg);
  background: linear-gradient(#4caf50, #009d6b);
  background: #ededed;
  .input-group-text,
  #successBtn,
  .title {
    background: linear-gradient(90deg, #4caf50, #009d6b);
    &,
    a {
      color: white;
    }
  }

  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: -20px;

  .regular-checkbox {
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
  }

  .regular-checkbox:active,
  .regular-checkbox:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .regular-checkbox:checked {
    background-color: #2196f3;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .regular-checkbox:checked:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: #fff;
  }

  #booking_form {
    background: rgba(255, 255, 255, 0.97);
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 0px solid #eaeaea;
    margin-top: 20px;
    margin-bottom: 20px;
    .form-control {
      margin-bottom: 0px;
    }

    .title {
      padding: 10px;
      border-bottom: 2px solid #eee;
      margin-bottom: 10px;
      .name {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .time-suggestion {
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  #buttons > div {
    margin-bottom: 10px;
  }
  .group_choice {
    cursor: pointer;
  }
  #title {
    .btn {
      color: white;
      font-size: 15px;
    }
  }
  #no_act {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    a {
      background: white;
      border-radius: 3px;
    }
  }
}
</style>
