<script>
import moment from "moment";
import jQuery from "jquery";
import api from "@/api";
import { MyComponent } from "@/components/MyComponent.js";
import Exception from "@/components/Exception.vue";
import DatePicker from "vue2-datepicker";
import SpaceLocationMixin from "@/mixins/SpaceLocation";
import BookingMixin from "@/mixins/Booking";
import "vue2-datepicker/index.css";

import TimePicker from "@/components/timepicki.vue";

export default {
  name: "RB",
  mixins: [MyComponent],
  components: {
    Exception,
    TimePicker,
    DatePicker,
  },
  data() {
    const today = moment().format("YYYY-MM-DD");
    return {
      recurrences: [],

      editable_recurrence: null,
      edit_initial_date: null,
      edit_ending_date: null,

      new_group_name: null,
      new_product: null,
      new_group: null,
      new_initial_date: today,
      new_ends_on_date: null,
      new_start_time: null,
      new_end_time: null,
      time1: null,
      new_is_internal_use: false,
      days_of_week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],

      exception: null,
      exception_date: null,
    };
  },
  methods: {
    getDisabledWhichDate(comparison_date) {
      if (!this.new_initial_date || !comparison_date) {
        return false;
      }
      const dayOfWeekStart = moment(this.exception.active_from_date).day();
      const dayOfWeek = moment(comparison_date).day();
      return dayOfWeek !== dayOfWeekStart;
    },
    getDisabledEndsOnDate(comparison_date) {
      if (!this.new_initial_date || !comparison_date) {
        return false;
      }
      const dayOfWeekStart = moment(this.new_initial_date).day();
      const dayOfWeek = moment(comparison_date).day();
      return dayOfWeek !== dayOfWeekStart;
    },
    setEditable(item) {
      this.editable_recurrence = item;
      this.edit_initial_date = item.active_from_date;
      this.edit_ending_date = item.ends_on_date;
    },
    saveEditable() {
      let endsOnDate = this.edit_ending_date;
      if (!endsOnDate) {
        endsOnDate = null;
      }

      if (!this.edit_initial_date) {
        alert("Must have initial date.");
        return;
      }

      const data = {
        active_from_date: this.edit_initial_date,
        ends_on_date: endsOnDate,
      };
      const url = "/Recurrence/" + this.editable_recurrence.id;
      api.Manager.axios.patch(url, data).then(() => {
        for (var i = 0; i < this.recurrences.length; i++) {
          var item = this.recurrences[i];
          if (item !== this.editable_recurrence) {
            continue;
          }
          item.active_from_date = this.edit_initial_date;
          item.ends_on_date = this.edit_ending_date;
          this.recurrences.splice(i, 1, item);
          break;
        }

        this.editable_recurrence = null;
        this.editable_date = null;
        alert("Success!");
      });
    },
    changeInternalUse(item, event) {
      var c = event.target.checked ? 1 : 0;
      return this.changeSingleItem(item, { is_internal_use: c });
    },
    changeItem(item, event) {
      var c = event.target.checked ? 1 : 0;
      return this.changeSingleItem(item, { active: c });
    },
    changeSingleItem(item, postData) {
      const url = "/Recurrence/" + item.id;
      api.Manager.axios.patch(url, postData);
    },
    addException(item) {
      this.exception = item;
      // this.rebind()
    },
    saveNewException(item) {
      var info = {
        recurrence: item.id,
        date: this.exception_date,
      };

      this.exception = null;
      this.exception_date = null;

      const url = "/Exceptions/";
      api.Manager.axios.post(url, info).then(() => {
        this.getRecurrences();
      });
    },
    deleteItem(item) {
      jQuery("#loader").show();
      const url = "/Recurrence/" + item.id;
      api.Manager.axios
        .delete(url)
        .then(() => {
          this.getRecurrences();
        })
        .catch(() => {
          jQuery("#loader").hide();
        });
    },
    getTitle(item) {
      if (item.group) {
        return item.group.name;
      }
      return item.title;
    },
    verifyStartTime(date) {
      this.new_start_time = date.time;
    },
    verifyEndTime(date) {
      this.new_end_time = date.time;
    },
    getRecurrences() {
      jQuery("#loader").show();
      api.SpaceLocations.recurrences(this.space.id).then((response) => {
        jQuery("#loader").hide();
        const data = response.data;
        var d = data;
        if (data.results) {
          d = data.results;
        }
        this.recurrences = d;
      });
    },
    addRecurrence() {
      jQuery("#loader").show();
      var url = "/Recurrences/" + this.slug;
      const timezoneOffset = new Date().getTimezoneOffset();
      const data = {
        title: this.new_group_name,
        product: this.new_product,
        start_time: this.new_start_time,
        end_time: this.new_end_time,
        is_internal_use: this.new_is_internal_use,
        active_from_date: this.new_initial_date,
        ends_on_date: this.new_ends_on_date,
        day_of_week: this.new_day_of_week,
        timezone_offset_mins: 0 - timezoneOffset, // offset must be reversed
      };

      api.Manager.axios
        .post(url, data)
        .then(() => {
          jQuery("#loader").hide();
          this.getRecurrences();
          this.new_group_name = null;
          this.new_start_time = null;
          this.new_end_time = null;
          this.new_is_internal_use = false;
        })
        .catch((err) => {
          console.error(err);
          jQuery("#loader").hide();
          this.getRecurrences();
        });
    },
    // rebind () {
    //   var p = this
    //     jQuery('.exception_date').datepicker({
    //       dateFormat: 'yy-mm-dd',
    //       minDate: '0',
    //       onSelect: function (dateText, obj) {
    //         console.log(obj)
    //       }
    //     })
    //   })
    // },
    removeException(itemId) {
      const url = "/Exception/" + itemId;
      api.Manager.axios.delete(url).then(() => {
        this.recurrences.splice(0);
        this.getRecurrences();
      });
    },
  },
  computed: {
    slProductObj() {
      const obj = this.products.find((item) => item.id == this.new_product);
      if (obj) {
        return obj;
      }
      return null;
    },
    slProductHours() {
      if (!this.slProductObj) {
        return null;
      }
      return this.slProductObj.duration_mins / 60;
    },
    spaceStartHourReadable() {
      if (!this.slProductObj) {
        return null;
      }
      return BookingMixin.readableHoursAndMins(
        this.slProductObj.start_time_mins
      );
    },
    openDays() {
      if (this.slProductObj && this.slProductObj.days_of_week_csv) {
        const daysOfWeek = this.slProductObj.days_of_week_csv.split(",");
        return daysOfWeek.join(", ");
      }
      return [];
    },
    new_day_of_week() {
      if (this.new_initial_date) {
        return moment(this.new_initial_date).format("ddd");
      }
      return "";
    },
    products() {
      if (this.space) {
        return this.space.products;
      }
      return [];
    },
    defaultProduct() {
      return SpaceLocationMixin.getDefaultProduct(this.space);
    },
    space() {
      return this.$store.getters["General/getSpaceBySlug"](this.slug);
    },
    slug() {
      return this.$route.params.slug;
    },
  },
  watch: {
    exception_date(dateText) {
      if (dateText) {
        var dow = moment(dateText).format("ddd").toString();
        if (dow !== this.exception.day_of_week) {
          const alertStr = `Date must be a ${this.exception.day_of_week}.`;
          alert(alertStr);
          this.exception_date = null;
        }
      }
    },
  },
  created() {
    this.getRecurrences();
    // this.rebind()
  },
};
</script>

<template>
  <div id="RecurringBookings" class="py-2">
    <div class="col">
      <div class="">
        <div class="row">
          <div class="col">
            <h3>Recurring Bookings</h3>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p>These are rehearsals that occur at the same time every week.</p>
            <p>
              Instead of having to add them every week, add them here, and then
              if any days, need to be skipped, exclude them.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      Group
                      <i class="fas fa-user text-primary"></i>
                    </th>
                    <th>Product</th>
                    <th>
                      Initial Date
                      <i
                        class="fas fa-question text-success"
                        title="When it should START appearing on the calendar."
                      ></i>
                    </th>
                    <th>
                      Ends On Date
                      <i
                        class="fas fa-question text-danger"
                        title="When it should STOP appearing on the calendar."
                      ></i>
                    </th>
                    <th>
                      Start Time
                      <i class="fas fa-clock text-success"></i>
                    </th>
                    <th>
                      End Time
                      <i class="fas fa-clock text-danger"></i>
                    </th>
                    <th>Exceptions</th>
                    <th>Active</th>
                    <th>
                      For Internal Use? <br />
                      <small>(Not charged)</small>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        id="group_name"
                        class="form-control"
                        type="text"
                        v-model="new_group_name"
                      />
                    </td>
                    <td>
                      <select class="form-control" v-model="new_product">
                        <template v-for="product of products">
                          <option :key="product.id" :value="product.id">
                            {{ product.name }} @ {{ product.hourly_rate }} per
                            hour
                          </option>
                        </template>
                      </select>
                      <small class="text-secondary" v-if="slProductObj">
                        {{ openDays }} from {{ spaceStartHourReadable }} for
                        {{ slProductHours }} hours.
                      </small>
                    </td>
                    <td>
                      <span class="d-inline-block px-2 text-center">
                        {{ new_day_of_week }}
                      </span>
                      <DatePicker
                        v-model="new_initial_date"
                        valueType="format"
                      />
                    </td>
                    <td>
                      <DatePicker
                        v-model="new_ends_on_date"
                        :disabled-date="getDisabledEndsOnDate"
                        valueType="format"
                      />
                    </td>
                    <td>
                      <time-picker
                        :show_30s="space.allow_half_hour_bookings"
                        id="timepicker2"
                        :digits="new_start_time"
                        @change="verifyStartTime"
                      />
                    </td>
                    <td>
                      <time-picker
                        :show_30s="space.allow_half_hour_bookings"
                        id="timepicker3"
                        :digits="new_end_time"
                        @change="verifyEndTime"
                      />
                    </td>
                    <td colspan="2"></td>
                    <td>
                      <input type="checkbox" v-model="new_is_internal_use" />
                    </td>
                    <td>
                      <button class="btn btn-primary" @click="addRecurrence">
                        Add Recurrence
                      </button>
                    </td>
                  </tr>
                  <template v-for="item in recurrences">
                    <tr
                      class="recurrence text-body"
                      :class="{
                        'alert-primary': item.is_internal_use,
                        'bg-warning': item.id == $route.params.ID,
                      }"
                      :key="item.id"
                    >
                      <td>{{ getTitle(item) }}</td>
                      <td>{{ item.product_obj.name }}</td>
                      <td>
                        {{ item.active_from_date }}
                      </td>
                      <td>
                        {{ item.ends_on_date }}
                      </td>
                      <td>{{ item.start_time }}</td>
                      <td>{{ item.end_time }}</td>
                      <td>
                        <template v-for="ex in item.exceptions">
                          <exception
                            :item="ex"
                            @remove="removeException"
                            :key="ex.id"
                          />
                        </template>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          v-model="item.active"
                          @change="changeItem(item, $event)"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          v-model="item.is_internal_use"
                          @change="changeInternalUse(item, $event)"
                        />
                      </td>
                      <td>
                        <button
                          class="btn btn-danger"
                          @click.prevent="deleteItem(item)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                        <button
                          class="btn btn-success"
                          @click.prevent="addException(item)"
                        >
                          Except these dates...
                        </button>
                      </td>
                    </tr>

                    <tr
                      v-if="exception && exception.id === item.id"
                      :key="item.id + '-save-exception'"
                    >
                      <td>
                        <DatePicker
                          v-model="exception_date"
                          valueType="format"
                          :disabled-date="getDisabledWhichDate"
                          placeholder="Except which date."
                        />
                      </td>
                      <td colspan="3">
                        <button
                          class="btn btn-success"
                          :disabled="
                            exception_date == null || exception_date == ''
                          "
                          @click="saveNewException(item)"
                        >
                          Save New Exception
                        </button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$small_size: 13px;

.ui-widget {
  font-family: Verdana, Arial, sans-serif;
  font-size: $small_size;
}

#RecurringBookings {
  background: white;
  border-radius: 3px;
  table.table,
  button.btn {
    font-size: $small_size;
  }
  table.table td {
    padding: 0.3rem;
  }
  button.btn {
    margin-right: 2px;
    margin-bottom: 2px;
  }

  .form-control {
    min-width: 80px;
    padding: 0.2rem;
    font-size: $small_size;
  }
  #group_name {
    min-width: 120px;
  }
}
#explain {
  background: #f6f6f6;
  border: #eee;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.recurrence {
  .edit {
    visibility: hidden;
  }
  .edit,
  .save {
    cursor: pointer;
    color: black;
  }
  .save {
    margin-left: 4px;
    font-size: 16px;
  }
  &:hover {
    transition: 0.4s all ease;
    .edit,
    .save {
      color: black;
      visibility: visible;
      &:hover {
        color: #28a745;
      }
    }
  }
}
</style>
